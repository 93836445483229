<div id="builder-header">

  <div class="bh-lp">
    <div class="selected-page">
      <div class="page-options" [matMenuTriggerFor]="pageOptionsMenu"
           *ngIf="selectedPage.addedPage">
        <fa-icon [icon]="faCog"></fa-icon>
      </div>
      <div class="page-data" [matMenuTriggerFor]="pageMenu">
        <div class="page-def">
          <div class="page-name"
               *ngIf="selectedPage.addedPage || !this.selectedPage.pageCode">{{currentPage.name}}</div>
          <div class="page-name"
               *ngIf="!selectedPage.addedPage && this.selectedPage.pageCode">{{ 'PAGE.' + currentPage.code | translate }}</div>
          <div class="page-slug">https://{{storeDomain}}/{{currentPage.slug}}</div>
        </div>
        <div class="arrow">
          <fa-icon [icon]="faChevronDown"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="bh-cp">
    <div class="shopyan-logo"></div>
  </div>

  <div class="bh-rp">

    <div class="display-mode">
      <div (click)="changeDisplayMode(2)" [class.current]="displayMode == 2" class="d-mode tablet"
           matTooltip="{{ 'HEADER.TABLET' | translate }}">
      </div>
      <div (click)="changeDisplayMode(0)" [class.current]="displayMode == 0" class="d-mode full"
           matTooltip="{{ 'HEADER.FULL_SCREEN' | translate }}">
      </div>
      <div (click)="changeDisplayMode(1)" [class.current]="displayMode == 1" class="d-mode laptop"
           matTooltip="{{ 'HEADER.LAPTOP' | translate }}">
      </div>
      <div (click)="changeDisplayMode(3)" [class.current]="displayMode == 3" class="d-mode mobile"
           matTooltip="{{ 'HEADER.MOBILE' | translate }}">
      </div>
    </div>
    <div class="publish-container">
      <button class="yan-btn secondary publish" (click)="publish()" yanLoadingBtn
              [loading]="loading">{{ 'HEADER.PUBLISH' | translate }}</button>
      <a class="preview" matTooltip="{{ 'HEADER.PREVIEW' | translate }}"
         *ngIf="currentPage.slug == 'product'"
         href="https://{{storeDomain}}" target="_blank">
      </a>
      <a class="preview" matTooltip="{{ 'HEADER.PREVIEW' | translate }}"
         *ngIf="currentPage.slug != 'product'"
         href="https://{{storeDomain}}/{{currentPage.slug}}" target="_blank">
      </a>
    </div>
    <div class="user-data">
      <div class="user-avatar">
        <span class="default-avatar">{{avatar}}</span>
      </div>
      <div class="more" [matMenuTriggerFor]="moreMenu"></div>
    </div>
  </div>

</div>


<!-- MORE MENU -->
<mat-menu #pageMenu>
  <div class="yan-menu" id="page-menu">
    <div class="yan-menu-padding">

      <ng-container *ngFor="let pageItem of graphTemplate.pages">
        <div (click)="selectPage(pageItem)"
             class="yan-page" mat-menu-item>
          <div class="page-ic">
          </div>
          <div class="page-name"
               *ngIf="!pageItem.addedPage">{{ 'PAGE.' + getPageStructure(pageItem.pageCode!)?.code | translate }}</div>
          <div class="page-name" *ngIf="pageItem.addedPage">{{pageItem.addedPage.name}}</div>
        </div>
      </ng-container>
      <ng-container *ngFor="let pageItem of graphTemplate.landingPages">
        <div (click)="selectPage(pageItem)"
             class="yan-page" mat-menu-item>
          <div class="page-ic">
          </div>
          <div class="page-name">{{pageItem.name}}</div>
        </div>
      </ng-container>

      <div (click)="openPageForm()" class="yan-btn white-btn add-page">
        <fa-icon [icon]="faPlus"></fa-icon>
        <span>{{ 'HEADER.NEW_PAGE' | translate }}</span>
      </div>

    </div>
  </div>
</mat-menu>


<mat-menu #pageOptionsMenu>
  <div class="yan-menu" id="page-options-menu">
    <div class="yan-menu-padding">
      <div (click)="openPageForm(true)"
           class="mat-menu-item ">{{ 'HEADER.EDIT_PAGE' | translate }}
      </div>
      <div (click)="deletePage()"
           class="mat-menu-item ">{{ 'HEADER.DELETE_PAGE' | translate }}
      </div>
    </div>
  </div>
</mat-menu>


<!-- MORE MENU -->
<mat-menu #moreMenu>
  <div id="more-menu" class="yan-menu">
    <div class="yan-menu-padding">
      <a mat-menu-item [href]="adminURL" target="_blank">{{ 'HEADER.GO_BACK' | translate }}</a>
      <a (click)="logout()" mat-menu-item>{{ 'HEADER.LOG_OUT' | translate }}</a>
    </div>
  </div>
</mat-menu>
