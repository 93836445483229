import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {FileUploadModule} from 'ng2-file-upload';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {ToastrModule} from 'ngx-toastr';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "@app/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BuilderLayoutComponent} from './component/builder-layout/builder-layout.component';
import {BuilderHeaderComponent} from './component/builder-header/builder-header.component';
import {BuilderMainMenuComponent} from './component/builder-main-menu/builder-main-menu.component';
import {BuilderDisplayComponent} from './component/builder-display/builder-display.component';
import {BuilderSectionsPaletteComponent} from './component/builder-sections-palette/builder-sections-palette.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CommonFormComponent} from './component/common-form/common-form.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {ImageUploaderComponent} from "@app/component/common-form/image-uploader/image-uploader.component";
import {SelectFieldComponent} from './component/common-form/select-field/select-field.component';
import {InputFieldComponent} from './component/common-form/input-field/input-field.component';
import {TextFieldComponent} from './component/common-form/text-field/text-field.component';
import {ColorFieldComponent} from './component/common-form/color-field/color-field.component';
import {SlideToggleFieldComponent} from './component/common-form/slide-toggle-field/slide-toggle-field.component';
import {SliderFieldComponent} from './component/common-form/slider-field/slider-field.component';
import {ConfirmationDialogComponent} from './component/confirmation-dialog/confirmation-dialog.component';
import {PageFormComponent} from './component/page-form/page-form.component';
import {EditorFieldComponent} from './component/common-form/editor-field/editor-field.component';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment'
import {StoreHttpInterceptor} from "@app/interceptor/store-http.interceptor";
import {AppRoutingModule} from "@app/app-routing.module";
import {HasErrorsDirective} from "@app/directive/has-error.directive";
import {FieldErrorComponent} from "@app/_shared/component/field-error/field-error.component";
import {RadioFieldComponent} from './component/common-form/radio-field/radio-field.component';
import {CheckFieldComponent} from './component/common-form/check-field/check-field.component';
import {ChipFieldComponent} from './component/common-form/chip-field/chip-field.component';
import {InternalErrorComponent} from "@app/_shared/component/error/internal-error/internal-error.component";
import {NotFoundComponent} from "@app/_shared/component/error/not-found/not-found.component";
import {UnauthorizedComponent} from "@app/_shared/component/error/unauthorized/unauthorized.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {YanLoadingButtonComponent} from "@app/_shared/component/yan-loading-button/yan-loading-button.component";
import {LinkFieldComponent} from './component/common-form/link-field/link-field.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {FroalaEditorModule} from "angular-froala-wysiwyg";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FileUploadModule,
    NgxSpinnerModule,
    FontAwesomeModule,
    LoadingBarModule,
    ToastrModule,
    MaterialModule,
    ColorPickerModule,
    FroalaEditorModule.forRoot(),
    KeycloakAngularModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      enableHtml: true,
      timeOut: 3600
    }),
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'fr'
    })
  ],
  declarations: [
    AppComponent,
    BuilderLayoutComponent,
    BuilderHeaderComponent,
    BuilderMainMenuComponent,
    BuilderDisplayComponent,
    BuilderSectionsPaletteComponent,
    CommonFormComponent,
    ImageUploaderComponent,
    SelectFieldComponent,
    InputFieldComponent,
    TextFieldComponent,
    ColorFieldComponent,
    SlideToggleFieldComponent,
    SliderFieldComponent,
    ConfirmationDialogComponent,
    PageFormComponent,
    EditorFieldComponent,
    HasErrorsDirective,
    FieldErrorComponent,
    RadioFieldComponent,
    CheckFieldComponent,
    ChipFieldComponent,
    InternalErrorComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    YanLoadingButtonComponent,
    LinkFieldComponent
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initializer,
    multi: true,
    deps: [KeycloakService]
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StoreHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function initializer(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloak,
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    });
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
