import {Component, DoCheck, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {faChevronRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {EventManager} from "@app/service/event-manager.service";
import {Subscription} from "rxjs";
import {EventManagerEnum} from "@app/model/enum/event-manager.enum";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "@app/component/confirmation-dialog/confirmation-dialog.component";
import {genUniqueId} from "@app/helper/builder-helper";
import {GraphPage} from "@app/model/graph-page.model";
import {GraphLandingPage} from "@app/model/graph-landing-page.model";
import {Section} from "@app/model/section.model";
import {GraphTemplate} from "@app/model/graph-template.model";
import {Block} from "@app/model/block.model";
import {BuilderField} from "@app/model/builder-field.model";
import {GraphFieldValue} from "@app/model/graph-field-value.model";
import {GraphSection} from "@app/model/graph-section.model";
import {GraphBlock} from "@app/model/graph-block.model";
import {SectionType} from "@app/model/enum/section-type.enum";
import {Direction} from "@angular/cdk/bidi";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-builder-main-menu',
  templateUrl: './builder-main-menu.component.html',
  styleUrls: ['./builder-main-menu.component.scss']
})
export class BuilderMainMenuComponent implements OnInit, DoCheck, OnDestroy {

  subscription: Subscription = new Subscription();

  /**
   * Icons
   */
  faPlus = faPlus;
  faChevronRight = faChevronRight;

  /**
   * Inputs
   */
  @Input()
  graphPage: GraphPage | GraphLandingPage;
  @Input()
  sections: Section[];
  @Input()
  defaultSections: string[];
  @Input()
  settingBlocks: Block[];
  @Input()
  template: GraphTemplate;


  /**
   * Used as parameters to section form
   */
  selectedSectionGraphCode: string;
  canAddBlock: boolean;
  paletteSections: Section[];

  /**
   * Modals display management
   */
  displaySectionsPalette = false;
  displayDynamicForm = false;

  /**
   * Section/block form
   */
  formLabel: string;
  formFields: BuilderField[];
  formValues: GraphFieldValue[];

  graphBeforeCheck: string;

  displaySetting: boolean;

  constructor(
    private translate: TranslateService,
    private eventManager: EventManager,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  /**
   * transfer modifications to common sections
   */
  ngDoCheck() {

    const sectionGraph = this.graphPage.sections?.find(item => item.code === this.selectedSectionGraphCode);
    if (!sectionGraph) {
      return;
    }
    const section = this.sections.find(section => section.code === sectionGraph.sectionCode)!;

    if (!section.common) {
      return;
    }

    // Spread the same data for the common sections through different pages
    const graphSTR = JSON.stringify(sectionGraph);
    if (this.graphBeforeCheck !== graphSTR) {
      this.template.pages.forEach((gp, pageIndex) => {
        gp.sections?.forEach((item, index) => {
          if (sectionGraph.sectionCode === item.sectionCode && sectionGraph.code !== item.code) {
            let newSection = {...sectionGraph};
            newSection.code = item.code;
            this.template.pages[pageIndex].sections?.splice(index, 1, newSection);
          }
        });
      });
      this.graphBeforeCheck = graphSTR;
    }
  }

  /**
   * Section click event (Event coming from the iframe)
   * @param event
   */
  @HostListener('window:message', ['$event'])
  onPostMessage(event: any) {
    const graphSection = this.graphPage.sections?.find(item => item.code === event.data);
    if (graphSection) {
      const section = this.sections.find(item => item.code === graphSection.sectionCode);
      if (section) {
        this.displaySectionsPalette = false;
        this.displayDynamicForm = false;
        this.displaySetting = false;
        this.displaySectionForm(graphSection, section);
      }
    }
  }


  /**
   * Toggle section
   *
   */
  toggleSectionGraph(code: string): void {
    if (this.selectedSectionGraphCode === code) {
      // Close section
      this.selectedSectionGraphCode = '';
    } else {
      this.openSectionGraph(code);
    }
  }

  /**
   * Open section
   * @param code
   * @private
   */
  private openSectionGraph(code: string): void {
    this.selectedSectionGraphCode = code;
    this.initCanAddBlock();
    this.eventManager.broadcast({name: EventManagerEnum.SELECT_SECTION, section: code});
  }

  /**
   * We can add a block in case of :
   *  + The current sections have blocks AND
   *     1. No block was selected
   *     2. OR at least one block is not yet selected
   *     3. OR there is a block can be selected multiple times
   */
  private initCanAddBlock(): void {
    const sectionGraph = this.graphPage.sections?.find(item => item.code === this.selectedSectionGraphCode)!;
    const section = this.sections.find(section => section.code === sectionGraph.sectionCode)!;
    if (section.possibleBlocks) {
      this.canAddBlock = section.possibleBlocks.length > 0 &&
        (!sectionGraph.blocks || sectionGraph.blocks.length < section.possibleBlocks.length ||
          section.possibleBlocks.some(block => !sectionGraph.blocks?.find(item => item.blockCode === block.code)) ||
          section.possibleBlocks.some(block => block.multiple === true));
    } else {
      this.canAddBlock = false;
    }
  }


  /**
   * On drag started
   * @param sectionCode
   */
  onDragStarted(sectionCode: string): void {
    this.eventManager.broadcast({name: EventManagerEnum.DRAG_DISPLAY_MODE});
    this.eventManager.broadcast({name: EventManagerEnum.SELECT_SECTION, section: sectionCode});
  }

  /**
   * On section drop action
   * @param event
   */
  drop(event: CdkDragDrop<GraphSection[]>) {
    moveItemInArray(this.graphPage.sections!, event.previousIndex, event.currentIndex);
    this.eventManager.broadcast({name: EventManagerEnum.DROP_DISPLAY_MODE});
    this.openSectionGraph(this.graphPage.sections![event.currentIndex].code);
  }

  /**
   * Delete section
   * @param sectionGraph
   */
  deleteSection(sectionGraph: GraphSection): void {

    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    let config = {
      data: this.translate.instant('MESSAGES.CONFIRM_DELETE_SECTION'),
      direction: dir,
      panelClass: ['yan-dialog'],
      closeOnNavigation: true
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.graphPage.sections?.splice(this.graphPage.sections?.indexOf(sectionGraph), 1);
      }
    }));
  }

  /**
   * Return blocks that can be added the current section
   */
  get possibleBlocks(): Block[] {

    const sectionGraph = this.graphPage.sections?.find(item => item.code === this.selectedSectionGraphCode);
    const section = this.sections.find(section => section.code === sectionGraph?.sectionCode);

    const blocks: Block[] = [];

    if (section && sectionGraph) {
      section.possibleBlocks?.forEach((possibleBlock: Block) => {
        if (possibleBlock.multiple || !sectionGraph.blocks?.some(item => item.blockCode === possibleBlock.code)) {
          blocks.push(possibleBlock);
        }
      });
    }
    return blocks;
  }

  /**
   * Add block to section
   * @param block
   */
  addBlockToSection(block: Block): void {
    const sectionGraph = this.graphPage.sections?.find(item => item.code === this.selectedSectionGraphCode)!;
    const graphBlock = new GraphBlock();
    graphBlock.blockCode = block.code;
    graphBlock.values = this.getFieldsValues(block.fields);
    if (!sectionGraph.blocks) {
      sectionGraph.blocks = [];
    }
    sectionGraph.blocks.push(graphBlock);
    this.initCanAddBlock();
  }


  /**
   * Delete a section block
   * @param blockGraph
   */
  deleteBlock(blockGraph: GraphBlock): void {
    const dir: Direction = this.translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    let config = {
      data: this.translate.instant('MESSAGES.CONFIRM_DELETE_BLOCK'),
      direction: dir,
      panelClass: ['yan-dialog'],
      closeOnNavigation: true
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        const sectionGraph = this.graphPage.sections?.find(item => item.code === this.selectedSectionGraphCode)!;
        sectionGraph.blocks?.splice(sectionGraph.blocks.indexOf(blockGraph), 1);
        this.initCanAddBlock();
      }
    }));
  }


  /**
   * Drop blocks
   * @param event
   */
  dropBlock(event: CdkDragDrop<GraphBlock[]>) {
    const sectionGraph = this.graphPage.sections?.find(item => item.code === this.selectedSectionGraphCode)!;
    const section = this.sections.find(section => section.code === sectionGraph.sectionCode)!;

    if (section.canOrderBlocks) {
      moveItemInArray(sectionGraph.blocks!, event.previousIndex, event.currentIndex);
    }
  }

  /**
   * Show section palette
   */
  showSectionPalette(): void {
    this.paletteSections = [...this.sections.filter(item =>
      !item.uniquePerPage || !this.graphPage.sections?.some(graphSection => graphSection.sectionCode === item.code))];
    this.displaySectionsPalette = true;
  }

  closeSectionPalette(): void {
    this.displaySectionsPalette = false;
  }

  /**
   * Add a section from possible sections to the current page
   * @param section
   */
  addSection(section: Section): void {
    const sectionGraph = new GraphSection();
    sectionGraph.code = genUniqueId();
    sectionGraph.sectionCode = section.code;

    let sectionToBeInitialisedByDefault = true;

    // Is it a common section ??
    let commonSection: GraphSection | undefined;
    if (section.common) {
      this.template.pages.forEach(page => {
        if (!commonSection) {
          commonSection = page.sections?.find(item => item.sectionCode === section.code);
        }
      });
      if (commonSection) {
        if (commonSection.values) {
          sectionGraph.values = [...commonSection.values];
        }
        if (commonSection.blocks) {
          sectionGraph.blocks = [...commonSection.blocks];
        }
        if (commonSection.fieldsGroups) {
          sectionGraph.fieldsGroups = [...commonSection.fieldsGroups];
        }
        sectionToBeInitialisedByDefault = false;
      }
    }

    // Default values
    if (sectionToBeInitialisedByDefault) {

      // Fields
      sectionGraph.values = this.getFieldsValues(section.fields);

      // Fields group
      sectionGraph.fieldsGroups = this.getBlocksValues(section.fieldsGroups);

      // Blocks
      sectionGraph.blocks = [];
      if (section.defaultBlocks) {

        const blocks: Block[] = [];
        section.defaultBlocks?.forEach((b: any) => {
          blocks.push(section.possibleBlocks?.find((block: any) => block.code === b)!);
        });

        sectionGraph.blocks = this.getBlocksValues(blocks);
      }

    }

    // ORDER following the type
    switch (section.type) {
      case SectionType.ANNOUNCEMENT_BAR:
        this.graphPage.sections?.unshift(sectionGraph);
        break;

      case SectionType.HEADER:
        const announcementBarSection = this.sections.find(item => item.type === SectionType.ANNOUNCEMENT_BAR);
        if (announcementBarSection && this.graphPage.sections!.length > 0 && this.graphPage.sections![0].sectionCode === announcementBarSection.code) {
          this.graphPage.sections?.splice(1, 0, sectionGraph);
        } else {
          this.graphPage.sections?.unshift(sectionGraph);
        }
        break;

      default:
        const footerSection = this.sections.find(item => item.type === SectionType.FOOTER);
        if (footerSection && this.graphPage.sections!.length > 0
          && this.graphPage.sections![this.graphPage.sections!.length - 1].sectionCode === footerSection.code) {
          this.graphPage.sections?.splice(this.graphPage.sections.length - 1, 0, sectionGraph);
        } else {
          this.graphPage.sections?.push(sectionGraph);
        }

    }


    this.closeSectionPalette();
    this.openSectionGraph(sectionGraph.code);
  }


  private getFieldsValues(fields: BuilderField[] | undefined): GraphFieldValue[] {
    const values: GraphFieldValue[] = [];
    if (fields) {
      fields.forEach(field => {
        const graphField = new GraphFieldValue();
        graphField.fieldCode = field.code;
        graphField.value = field.defaultValue;
        values?.push(graphField);
      });
    }
    return values;
  }

  private getBlocksValues(blocks: Block[] | undefined): GraphBlock[] {
    const graphBlocks: GraphBlock[] = [];
    if (blocks) {
      blocks.forEach(block => {
        const graphBlock = new GraphBlock();
        graphBlock.blockCode = block.code;
        graphBlock.hidden = block.hiddenByDefault;
        graphBlock.values = this.getFieldsValues(block.fields);
        graphBlocks.push({...graphBlock});
      });
    }
    return graphBlocks;
  }


  /**
   * Display section form
   * @param graphSection
   * @param section
   */
  displaySectionForm(graphSection: GraphSection, section: Section): void {
    if (section.fields && section.fields.length > 0) {
      this.formLabel = section.label;
      this.formFields = section.fields;
      if (graphSection.values && graphSection.values.length > 0) {
        this.formValues = graphSection.values;
      } else {
        this.formValues = [];
      }
      this.openSectionGraph(graphSection.code);
      this.displayDynamicForm = true;
    } else {
      this.toggleSectionGraph(graphSection.code);
    }
  }

  /**
   * Display block form
   * @param graphBlock
   * @param block
   */
  displayBlockForm(graphBlock: GraphBlock, block: Block): void {
    if (block.fields && block.fields.length > 0) {
      this.formLabel = block.label;
      this.formFields = block.fields;
      if (graphBlock.values && graphBlock.values.length > 0) {
        this.formValues = graphBlock.values;
      } else {
        this.formValues = [];
      }
      this.displayDynamicForm = true;
    }
  }

  /**
   * Close dynamic form
   */
  closeDynamicForm(): void {
    this.displayDynamicForm = false;
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

