import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractFieldComponent} from "@app/component/abstrat-field.component";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";
import {getStoreId} from "@app/helper/builder-helper";
import {MediaSize, MediaType} from "@app/model/enum/media-type.enum";
import {YanToastrService} from "@app/service/yan-toastr.service";
import {BuilderService} from "@app/service/builder.service";
import 'froala-editor/js/plugins/table.min'; // Table
import 'froala-editor/js/plugins/align.min'; // Align
import 'froala-editor/js/plugins/special_characters.min'; // Special Characters
import 'froala-editor/js/plugins/char_counter.min'; // Character Counter
import 'froala-editor/js/plugins/code_beautifier.min'; // Code Beautifier
import 'froala-editor/js/plugins/code_view.min'; // Code View
import 'froala-editor/js/plugins/colors.min'; // Colors
import 'froala-editor/js/plugins/draggable.min'; // Draggable
import 'froala-editor/js/plugins/emoticons.min'; // Emoticons
import 'froala-editor/js/plugins/entities.min'; // Entities
import 'froala-editor/js/plugins/file.min'; // File
import 'froala-editor/js/plugins/print.min'; // Print
import 'froala-editor/js/plugins/font_family.min'; // Font Family
import 'froala-editor/js/plugins/font_size.min'; // Font Size
import 'froala-editor/js/plugins/fullscreen.min'; // Fullscreen
import 'froala-editor/js/plugins/image.min'; // Image
import 'froala-editor/js/plugins/image_manager.min'; // Image Manager
import 'froala-editor/js/plugins/inline_style.min'; // Inline Style
import 'froala-editor/js/plugins/line_breaker.min'; // Line Breaker
import 'froala-editor/js/plugins/link.min'; // Link
import 'froala-editor/js/plugins/lists.min'; // Lists
import 'froala-editor/js/plugins/paragraph_format.min'; // Paragraph Format
import 'froala-editor/js/plugins/paragraph_style.min'; // Paragraph Style
import 'froala-editor/js/plugins/quick_insert.min'; // Quick Insert
import 'froala-editor/js/plugins/quote.min'; // Quote
import 'froala-editor/js/plugins/save.min'; // Save
import 'froala-editor/js/plugins/url.min'; // URL
import 'froala-editor/js/plugins/video.min'; // Video
import 'froala-editor/js/plugins/word_paste.min'; // Word Paste
import 'froala-editor/js/plugins/help.min'; // Help

@Component({
  selector: 'app-editor-field',
  templateUrl: './editor-field.component.html',
  styleUrls: ['./editor-field.component.scss']
})
export class EditorFieldComponent extends AbstractFieldComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  mimeTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'image/webp'];


  constructor(private translate: TranslateService,
              private builderService: BuilderService,
              private yanToastrService: YanToastrService) {
    super();
  }

  ngOnInit(): void {
  }

  // EDITOR
  private editorInstance: any;
  editorOptions: Object = {
    key: 'lSA3D-17D2E2E1H2A1D1C3sGXd1WWTDSGXYOUKh1KINLb1OG1g1H-8D1B3D3C1E6C1G1B4E4J4==',
    toolbarSticky: true,
    toolbarStickyOffset: 65,
    videoInsertButtons: ['videoByURL'],
    imageInsertButtons: ['imageUpload'],
    toolbarButtons: [
      'fullscreen', 'bold', 'italic', 'underline', 'strikeThrough',
      'fontFamily', 'fontSize', 'textColor', 'backgroundColor',
      'subscript', 'superscript',
      'paragraphFormat', 'paragraphStyle', 'lineHeight','insertTable',
      'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-',
      'insertImage', 'insertVideo', 'insertLink',
      'emoticons', 'specialCharacters', 'insertHR', 'clearFormatting',
      'selectAll', 'html', 'quote',
      'undo', 'redo', 'help'
    ],
    fontFamily: {
      'Roboto': 'Roboto, sans-serif',
      'Open Sans': 'Open Sans, sans-serif',
      'Lato': 'Lato, sans-serif',
      'Montserrat': 'Montserrat, sans-serif',
      'Poppins': 'Poppins, sans-serif',
      'Raleway': 'Raleway, sans-serif',
      'Nunito': 'Nunito, sans-serif',
    },
    pluginsEnabled: [
      'table', 'align', 'specialCharacters', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable',
      'emoticons', 'entities', 'file', 'print', 'fontFamily', 'fontSize', 'fullscreen',
      'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists',
      'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save',
      'url', 'video', 'wordPaste', 'help'
    ],
    events: {
      'initialized': (editor: any) => this.editorInstance = editor.getEditor(),
      'image.beforeUpload': this.onImageBeforeUpload.bind(this),
      'image.error': this.onImageUploadError.bind(this),
      'image.beforePasteUpload': this.beforePasteUpload.bind(this),
      'paste.after': this.pastAfter.bind(this),
    }
  };

  beforePasteUpload() {
    return false;
  }

  pastAfter() {
    const content = this.editorInstance.html.get();
    if (/<img[^>]+src="[^"]+"[^>]*>/g.test(content)) {
      setTimeout(() => {
        this.editorInstance.html.set(content);
      }, 500);
    }
  }

  // Handle the image upload before sending it to the server
  onImageBeforeUpload(files: any) {
    if (!this.mimeTypes.includes(files[0].type)) {
      this.yanToastrService.error(this.translate.instant('COMMON.EDITOR_FIELD.ERROR_MIME_TYPE'));
      return false;
    }

    if (files[0].size > 5 * 1048576) {
      this.yanToastrService.error(this.translate.instant('COMMON.EDITOR_FIELD.ERROR_MAX_SIZE'));
      return false;
    }

    this.builderService.uploadImage(files[0]).subscribe({
      next: (mediaId: any) => {

        const url = environment.mediaURL + '/' + getStoreId() + '/' + MediaType.STORE.toLowerCase()
          + '/' + MediaSize.original + '/' + mediaId + '.webp';
        const imgHtml = `<img src="${url}" alt="Image" style="max-width: 100%; height: auto;" />`;
        this.editorInstance.html.insert(imgHtml);

      }, error: error => {
        this.onImageUploadError();
      }
    });

    return false;
  }


  // Handle errors during image upload
  onImageUploadError() {
    this.yanToastrService.error(this.translate.instant('COMMON.EDITOR_FIELD.MEDIA.ERROR_IMPORT'));
  }


  /**
   * Unsubscribe
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
